<template>
  <div class="group_28 flex-col" ref="footer">
    <div class="szh_group1 flex-col">
      <div class="text-wrapper_12 flex-row">
        <span class="text_63">孙中旗</span>
        <span class="text_64">友情链接</span>
        <span class="text_65">常见问题</span>
        <span class="text_66">其他</span>
      </div>
      <div class="text-wrapper_13 flex-row">
        <span class="text_67">copyright&#64;2023 -&nbsp;toop.cool</span>
        <span class="text_68">站酷</span>
        <span class="text_69">服务相关</span>
        <span class="text_70">素材下载</span>
      </div>
      <div class="text-wrapper_14 flex-row">
        <span class="text_71">
          <a href="https://beian.miit.gov.cn/" target="_blank" class="text_71">京ICP备2023000920号-1</a>
        </span>

        <span class="text_72">花瓣网</span>
        <span class="text_73">备案相关</span>
        <span class="text_74">设计交流</span>
      </div>
      <div class="text-wrapper_15 flex-row justify-between">
        <span class="text_71" style="margin-top :0; margin-right: 476px;;">
          <a style="color: #ffffffaf;" href= "https://beian.mps.gov.cn/#/query/webSearch?code=11011302006920\" rel="noreferrer" target="_blank">京公网安备11011302006920</a>
        </span>
        <span class="text_75">百度搜索</span>
        <span class="text_76">计费相关</span>
        <span class="text_77">开发人员</span>
      </div>
      <div class="box_20 flex-row">
        <div class="image-wrapper_2 flex-col">
          <img
              class="thumbnail_5"
              referrerpolicy="no-referrer"
              src="../../../assets/img/SketchPng078a0d1ead3a4dfe2df60d15688c8a8f780f3b215348a3ef21deb8c34eac2c10.png"
          />
        </div>
        <div class="image-wrapper_3 flex-col">
          <img
              class="image_17"
              referrerpolicy="no-referrer"
              src="../../../assets/img/SketchPng39955670cae5a1efa7dcf39dbabb3caba995b0a67c5007b92a8c0711f16c607e.png"
          />
        </div>
        <div class="image-wrapper_4 flex-col">
          <img
              class="thumbnail_6"
              referrerpolicy="no-referrer"
              src="../../../assets/img/SketchPngc5e1727c123c8975c27920a20f98812a694ebaa2e9e43c4b003ae86a2ce52612.png"
          />
        </div>
        <span class="text_78">酷家乐</span>
      </div>
      <div class="text-wrapper_16 flex-row"><span class="text_79">极有家</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyFooter"
}
</script>

<style scoped>

.group_28 {
  background-color: rgba(30, 59, 108, 1);
  height: 400px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.text-wrapper_12 {
  width: 994px;
  height: 25px;
  margin: 54px 0 0 207px;
}

.text_63 {
  width: 54px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: DINPro-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.text_64 {
  width: 72px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: DINPro-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 556px;
}

.text_65 {
  width: 72px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: DINPro-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 102px;
}

.text_66 {
  width: 36px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: DINPro-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 102px;
}

.text-wrapper_13 {
  width: 1022px;
  height: 25px;
  margin: 22px 0 0 207px;
}

.text_67 {
  width: 221px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}

.text_68 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 389px;
}

.text_69 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 142px;
}

.text_70 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color:#ffffffaf;
  font-size: 16px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 110px;
}

.text-wrapper_14 {
  width: 1022px;
  height: 30px;
  margin: 20px 0 0 207px;
}

.text_71 {
  width: 136px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 8px;
}

.text_72 {
  width: 48px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 474px;
}

.text_73 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 126px;
}

.text_74 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 110px;
}

.text-wrapper_15 {
  width: 412px;
  height: 22px;
  margin: 14px 0 0 204px;
}

.text_75 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.text_76 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 110px;
}

.text_77 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-left: 110px;
}

.box_20 {
  width: 658px;
  height: 44px;
  margin: 10px 0 0 207px;
}

.image-wrapper_2 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  height: 42px;
  width: 42px;
}

.thumbnail_5 {
  width: 15px;
  height: 12px;
  margin: 15px 0 0 14px;
}

.image-wrapper_3 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  height: 42px;
  margin-left: 34px;
  width: 42px;
}

.image_17 {
  width: 22px;
  height: 8px;
  margin: 17px 0 0 10px;
}

.image-wrapper_4 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  height: 42px;
  margin-left: 35px;
  width: 42px;
}

.thumbnail_6 {
  width: 12px;
  height: 12px;
  margin: 15px 0 0 15px;
}

.text_78 {
  width: 48px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 22px 0 0 415px;
}

.text-wrapper_16 {
  width: 48px;
  height: 22px;
  margin: 22px 0 99px 817px;
}

.text_79 {
  width: 48px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffffaf;
  font-size: 16px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.group_29 {
  background-color: rgba(57, 172, 110, 1);
  border-radius: 50%;
  position: absolute;
  left: 670px;
  top: 587px;
  width: 33px;
  height: 33px;
}

.group_30 {
  background-color: rgba(242, 245, 250, 1);
  position: absolute;
  left: 211px;
  top: 27px;
  width: 813px;
  height: 240px;
  z-index: 1;
}

.text-wrapper_17 {
  width: 548px;
  height: 43px;
  overflow-wrap: break-word;
  font-size: 0;
  letter-spacing: 0.800000011920929px;
  text-transform: uppercase;
  font-family: ToppanBunkyuMidashiGothicStdN-ExtraBold;
;
  text-align: left;
  white-space: nowrap;
  line-height: 65px;
  margin: 66px 0 0 137px;
}

.text_80 {
  width: 548px;
  height: 43px;
  overflow-wrap: break-word;
  color: rgba(30, 59, 108, 1);
  font-size: 43px;
  text-transform: uppercase;
  font-family: DINPro-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 65px;
}

.text_81 {
  width: 548px;
  height: 43px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 43px;
  text-transform: uppercase;
  font-family: DINPro-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 65px;
}

.text_82 {
  width: 548px;
  height: 43px;
  overflow-wrap: break-word;
  color: rgba(178, 73, 58, 1);
  font-size: 43px;
  text-transform: uppercase;
  font-family: DINPro-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 65px;
}

.text_83 {
  width: 192px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(30, 59, 108, 1);
  font-size: 24px;
;
  text-align: left;
  white-space: nowrap;
  line-height: 33px;
  margin: 32px 0 66px 310px;
}
</style>
