var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('Menu',{attrs:{"background":"rgba(255, 255, 255, 1)"}}),_vm._m(0),_c('div',{staticClass:"block_3 justify-center"},[_c('div',{staticClass:"image-text_1"},[_c('img',{staticClass:"image_1",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPng48955961b943af75c9651c9f9fe3d16817c954cdf5a5eccd8addda3f30c14235.png")}}),_vm._m(1),_c('img',{staticClass:"image_2",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPngf883d2dd53c4eee703bfb858dea4bfcf66008ea4073f202d9be9f451369ebb45.png")},on:{"click":_vm.scrollToBottom}})])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('img',{staticClass:"image_3",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPngd6ebe1d088e4073043c1a2563f82f22f05c71cb833d3fb376201b2e9a7041f49.png")}}),_c('Footer',{ref:"footer"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"block_2 flex-col"},[_c('div',{staticClass:"box_1 flex-col justify-end"},[_c('div',{staticClass:"text-group_1 flex-col justify-between"},[_c('span',{staticClass:"text_8"},[_vm._v("advanced")]),_c('span',{staticClass:"text_9"},[_vm._v("house")]),_c('span',{staticClass:"text_10"},[_vm._v("高级的家带来高级的体验")])]),_c('img',{staticClass:"label_1",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPngeb76e59e218516be75bcb088e949032db0c6ad2bdac0c37919ae4d548ee2d80c.png")}}),_c('div',{staticClass:"group_1 flex-col"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_2 flex-col"},[_c('span',{staticClass:"text_11"},[_vm._v("一千个人")]),_c('span',{staticClass:"text_12"},[_vm._v("就有一千个对家的想象")]),_c('span',{staticClass:"text_13"},[_vm._v(" A thousand people have a thousand visions of home ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jxxm"},[_c('p',{staticClass:"text_14"},[_vm._v("精选项目")]),_c('p',{staticClass:"text_15"},[_vm._v("S/e/l/e/c/t/e/d")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block_4 justify-center"},[_c('div',{staticClass:"banner2 justify-start"},[_c('div',{staticClass:"box_4 flex-col"}),_c('div',{staticClass:"box_2 flex-col"},[_c('div',{staticClass:"box_3 flex-col"})]),_c('div',{staticClass:"box_5 flex-col"},[_c('div',{staticClass:"text-group_3 flex-col justify-between"},[_c('span',{staticClass:"text_16"},[_vm._v("北京 朝阳 逸翠园")]),_c('span',{staticClass:"text_17"},[_vm._v("装修时间：2021.09-2022.05")])]),_c('div',{staticClass:"group_2 flex-col"}),_c('span',{staticClass:"paragraph_1"},[_vm._v(" 究么们决打交光长事商领许立美速存战构于交题出由音张资经队问光世改基不他装况书说边事参身农海机声采积般实命细响积张记。 "),_c('br'),_vm._v(" 三传些江可际目合术品入族人义立参解选金学叫见件联即在小部写导识七影时再达小小想及发江而大。光日革气心影统先府标须示出义程土百酸区商九必就次什们包金量直。 "),_c('br'),_vm._v(" 发七准走准历市都主话往计每百器六这据大矿老图直之影种反真事位其联导式叫二争成来图。 ")]),_c('div',{staticClass:"text-wrapper_3 flex-col"},[_c('span',{staticClass:"text_18"},[_vm._v("查看详情")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block_5 flex-row"},[_c('div',{staticClass:"banner2 justify-start"},[_c('div',{staticClass:"section_1 flex-col"},[_c('div',{staticClass:"text-group_4 flex-col justify-between"},[_c('span',{staticClass:"text_19"},[_vm._v("北京 朝阳 国风上观")]),_c('span',{staticClass:"text_20"},[_vm._v("装修时间：2021.12-2022.11")])]),_c('div',{staticClass:"section_2 flex-col"}),_c('span',{staticClass:"paragraph_2"},[_vm._v(" 究么们决打交光长事商领许立美速存战构于交题出由音张资经队问光世改基不他装况书说边事参身农海机声采积般实命细响积张记。 "),_c('br'),_vm._v(" 三传些江可际目合术品入族人义立参解选金学叫见件联即在小部写导识七影时再达小小想及发江而大。光日革气心影统先府标须示出义程土百酸区商九必就次什们包金量直。 "),_c('br'),_vm._v(" 发七准走准历市都主话往计每百器六这据大矿老图直之影种反真事位其联导式叫二争成来图。 ")]),_c('div',{staticClass:"text-wrapper_4 flex-col"},[_c('span',{staticClass:"text_21"},[_vm._v("查看详情")])])]),_c('div',{staticClass:"box_7 flex-col"}),_c('div',{staticClass:"section_3 flex-col"},[_c('div',{staticClass:"box_6 flex-col"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block_6 flex-row justify-between"},[_c('div',{staticClass:"box_8 flex-col justify-between"},[_c('div',{staticClass:"box_9 flex-row justify-between"},[_c('div',{staticClass:"box_10 flex-col"}),_c('div',{staticClass:"box_11 flex-col"})]),_c('div',{staticClass:"box_12 flex-row justify-between"},[_c('div',{staticClass:"group_3 flex-col"}),_c('div',{staticClass:"group_4 flex-col"})])]),_c('div',{staticClass:"box_13 flex-col"},[_c('div',{staticClass:"text-group_5 flex-col justify-between"},[_c('span',{staticClass:"text_22"},[_vm._v("北京 朝阳 国际万象")]),_c('span',{staticClass:"text_23"},[_vm._v("装修时间：2021.02-2022.03")])]),_c('div',{staticClass:"box_14 flex-col"}),_c('div',{staticClass:"box_15 flex-col"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-wrapper_5 flex-col"},[_c('span',{staticClass:"text_24"},[_vm._v("查看详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jxxm"},[_c('p',{staticClass:"text_14"},[_vm._v("其他项目")]),_c('p',{staticClass:"text_15"},[_vm._v("o/r/t/h/e/r")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block_7 flex-row"},[_c('div',{staticClass:"box_16 flex-row"},[_c('div',{staticClass:"image-text_2 flex-col justify-between"},[_c('div',{staticClass:"group_5 flex-col"}),_c('div',{staticClass:"text-group_6 flex-col justify-between"},[_c('span',{staticClass:"text_27"},[_vm._v("北京 海淀 万福园")]),_c('span',{staticClass:"text_28"},[_vm._v("装修时间：2020.06-2021.09")])])])]),_c('div',{staticClass:"box_17 flex-col justify-between"},[_c('div',{staticClass:"box_18 flex-col"}),_c('div',{staticClass:"text-group_7 flex-col justify-between"},[_c('span',{staticClass:"text_29"},[_vm._v("北京 朝阳 观湖国际")]),_c('span',{staticClass:"text_30"},[_vm._v("装修时间：2020.06-2021.09")])])]),_c('div',{staticClass:"box_19 flex-col justify-between"},[_c('div',{staticClass:"group_6 flex-col"}),_c('div',{staticClass:"text-group_8 flex-col justify-between"},[_c('span',{staticClass:"text_31"},[_vm._v("北京 顺义 首开琅樾")]),_c('span',{staticClass:"text_32"},[_vm._v("装修时间：2020.06-2021.09")])])]),_c('div',{staticClass:"image-text_3 flex-col justify-between"},[_c('div',{staticClass:"section_4 flex-col"}),_c('div',{staticClass:"text-group_9 flex-col justify-between"},[_c('span',{staticClass:"text_33"},[_vm._v("北京 通州 金福家园")]),_c('span',{staticClass:"text_34"},[_vm._v("装修时间：2020.06-2021.09")])])])])
}]

export { render, staticRenderFns }