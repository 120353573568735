<template>
  <div class="page flex-col">
    <Menu background="rgba(255, 255, 255, 1)"></Menu>
    <div class="section_2 justify-center">
      <div class="flex-col">
        <span class="text_8">装修中你遇到了什么麻烦？</span>
        <span class="text_9">每周更新一篇装修小知识</span>
        <div class="box_1 flex-row">
          <div class="text-wrapper_3 flex-col"><span class="text_10">搜索你感兴趣的内容吧</span></div>
          <div class="text-wrapper_4 flex-col"><span class="text_11">搜索</span></div>
        </div>
      </div>

    </div>
    <div class="justify-center flex-col">
      <div class="section_3 flex-row">
        <div class="text-wrapper_5 flex-col justify-between">
          <span class="text_12">7-15</span>
          <span class="text_13">2022</span>
        </div>
        <img
            class="thumbnail_1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng6477f9ba62f309da1d1720ae9715c4cfcaa4aa6dd77701c01535c6f34b3177ee.png"
        />
        <span class="text_14">最新更新</span>
        <div class="block_1 flex-col"></div>
        <span class="text_15">装修小知识：水管老化怎么办？这三招教你解决你能遇到的所有问题</span>
        <span class="paragraph_1">
      北技打发京济技得总体再样价该你几号长极思一价以术种后观九目会干易她。
      <br />
      标离解之报是得表须地程亲期起素体近度位结青手越种声较东则派具知响世个多候成必商究叫温金。
      <br />
    </span>
        <div class="section_4 flex-row">
          <span class="text_16">装修</span>
          <div class="box_2 flex-col"></div>
        </div>
        <div class="section_15 flex-col"></div>
        <div class="text-wrapper_6 flex-col"><span class="text_17">查看详情</span></div>
      </div>
    </div>

    <div class="section_5 ">
      <div class="flex-row justify-between">
        <div class="section_8 flex-col">
          <div class="text-wrapper_9 flex-col"><span class="text_23">4-11/2022</span></div>
        </div>
        <div class="block_6 flex-col">
          <div class="text-wrapper_10 flex-col"><span class="text_24">装修干货：刮大白也是有讲究的，这样…</span></div>
        </div>
        <div class="group_2 flex-col">
          <div class="text-wrapper_11 flex-col"><span class="text_25">装修干货：刮大白也是有讲究的，这样…</span></div>
        </div>
      </div>
      <div class="group_1 flex-col">
        <div class="block_2 flex-col">
          <div class="text-wrapper_7 flex-row"><span class="text_18">设计篇：如何能用最少的钱装出最好的效果</span></div>
          <div class="section_6 flex-row">
            <div class="block_3 flex-col justify-between">
              <span class="text_19">每克此术复争知无层进方海感易准区风口选成千次开是即机转土议…</span>
              <div class="box_3 flex-row justify-between">
                <span class="text_20">软装</span>
                <span class="text_21">设计</span>
                <div class="block_4 flex-col"></div>
                <div class="block_5 flex-col"></div>
              </div>
            </div>
            <img
                class="label_1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd97a4a35aa0855c3a87112d7768847cdd7111e0df053de3e308543d1fd3aefbb.png"
            />
            <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng196ab270c99475b09a64013822bd0e24608a845e6cc4a615822464f1bf241db8.png"
            />
          </div>
          <div class="section_7 flex-row">
            <div class="text-wrapper_8 flex-col"><span class="text_22">查看详情</span></div>
          </div>

        </div>
      </div>
    </div>

    <div class="section_16 flex-col">
      <div class="section_9 flex-col"></div>
      <div class="section_17 flex-col">
        <div class="text-group_6 flex-col justify-between">
          <span class="text_85">TELL&nbsp;ME</span>
          <span class="text_86">如果你在装修中遇到了什么麻烦，可以留言告诉我，我会尽力为你解答</span>
        </div>
        <div class="group_9 flex-row justify-between">
          <div class="text-wrapper_41 flex-col"><span class="text_87">留言板</span></div>
          <div class="text-wrapper_42 flex-col"><span class="text_88">写留言</span></div>
        </div>
      </div>
      <div class="section_10 flex-row">
        <div class="group_3 flex-col"></div>
        <div class="image-wrapper_1 flex-col" @click="scrollToBottom">
          <img
              class="label_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngdf9d252a85755084424ed158eab4a50472531120fc19fa034fe90330c1e0e76a.png"
          />
        </div>
      </div>
    </div>
    <div class="flex-col justify-center">
      <div class="image-text_1 flex-row justify-between">
        <div class="image-wrapper_2 flex-col">
          <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng06bc4f678d176608001bfd52d2ab274646fa30a6ad261514ae7a7a5765b8f183.png"
          />
        </div>
        <div class="box_4 flex-col">
          <div class="text-group_1 flex-col justify-between">
            <div class="text-wrapper_12 flex-row justify-between">
              <span class="text_26">装修小知识：房间里太潮，可以这么做，避免损坏家居</span>
              <span class="text_27">5-23/2022</span>
            </div>
            <span class="text_28">
            每克此术复争知无层进方海感易准区风口选成千次开是即机转土议年证采完阶红间地第布容海须给政决任据市选头先。经种连组最二月风石重论力清县原活张技被四务共电则属声每时市头只。
          </span>
            <div class="text-wrapper_13 flex-row justify-between">
              <span class="text_29">软装</span>
              <span class="text_30">知识</span>
            </div>
          </div>
          <div class="box_5 flex-col"></div>
          <div class="box_6 flex-col"></div>
          <div class="text-wrapper_14 flex-col"><span class="text_31">查看详情</span></div>
        </div>
      </div>
      <div class="image-text_1 flex-row justify-between">
        <div class="image-wrapper_2 flex-col">
          <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng06bc4f678d176608001bfd52d2ab274646fa30a6ad261514ae7a7a5765b8f183.png"
          />
        </div>
        <div class="box_4 flex-col">
          <div class="text-group_1 flex-col justify-between">
            <div class="text-wrapper_12 flex-row justify-between">
              <span class="text_26">装修小知识：房间里太潮，可以这么做，避免损坏家居</span>
              <span class="text_27">5-23/2022</span>
            </div>
            <span class="text_28">
            每克此术复争知无层进方海感易准区风口选成千次开是即机转土议年证采完阶红间地第布容海须给政决任据市选头先。经种连组最二月风石重论力清县原活张技被四务共电则属声每时市头只。
          </span>
            <div class="text-wrapper_13 flex-row justify-between">
              <span class="text_29">软装</span>
              <span class="text_30">知识</span>
            </div>
          </div>
          <div class="box_5 flex-col"></div>
          <div class="box_6 flex-col"></div>
          <div class="text-wrapper_14 flex-col"><span class="text_31">查看详情</span></div>
        </div>
      </div>
      <div class="image-text_1 flex-row justify-between">
        <div class="image-wrapper_2 flex-col">
          <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng06bc4f678d176608001bfd52d2ab274646fa30a6ad261514ae7a7a5765b8f183.png"
          />
        </div>
        <div class="box_4 flex-col">
          <div class="text-group_1 flex-col justify-between">
            <div class="text-wrapper_12 flex-row justify-between">
              <span class="text_26">装修小知识：房间里太潮，可以这么做，避免损坏家居</span>
              <span class="text_27">5-23/2022</span>
            </div>
            <span class="text_28">
            每克此术复争知无层进方海感易准区风口选成千次开是即机转土议年证采完阶红间地第布容海须给政决任据市选头先。经种连组最二月风石重论力清县原活张技被四务共电则属声每时市头只。
          </span>
            <div class="text-wrapper_13 flex-row justify-between">
              <span class="text_29">软装</span>
              <span class="text_30">知识</span>
            </div>
          </div>
          <div class="box_5 flex-col"></div>
          <div class="box_6 flex-col"></div>
          <div class="text-wrapper_14 flex-col"><span class="text_31">查看详情</span></div>
        </div>
      </div>
      <div class="image-text_1 flex-row justify-between">
        <div class="image-wrapper_2 flex-col">
          <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng06bc4f678d176608001bfd52d2ab274646fa30a6ad261514ae7a7a5765b8f183.png"
          />
        </div>
        <div class="box_4 flex-col">
          <div class="text-group_1 flex-col justify-between">
            <div class="text-wrapper_12 flex-row justify-between">
              <span class="text_26">装修小知识：房间里太潮，可以这么做，避免损坏家居</span>
              <span class="text_27">5-23/2022</span>
            </div>
            <span class="text_28">
            每克此术复争知无层进方海感易准区风口选成千次开是即机转土议年证采完阶红间地第布容海须给政决任据市选头先。经种连组最二月风石重论力清县原活张技被四务共电则属声每时市头只。
          </span>
            <div class="text-wrapper_13 flex-row justify-between">
              <span class="text_29">软装</span>
              <span class="text_30">知识</span>
            </div>
          </div>
          <div class="box_5 flex-col"></div>
          <div class="box_6 flex-col"></div>
          <div class="text-wrapper_14 flex-col"><span class="text_31">查看详情</span></div>
        </div>
      </div>
      <div class="image-text_1 flex-row justify-between">
        <div class="image-wrapper_2 flex-col">
          <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng06bc4f678d176608001bfd52d2ab274646fa30a6ad261514ae7a7a5765b8f183.png"
          />
        </div>
        <div class="box_4 flex-col">
          <div class="text-group_1 flex-col justify-between">
            <div class="text-wrapper_12 flex-row justify-between">
              <span class="text_26">装修小知识：房间里太潮，可以这么做，避免损坏家居</span>
              <span class="text_27">5-23/2022</span>
            </div>
            <span class="text_28">
            每克此术复争知无层进方海感易准区风口选成千次开是即机转土议年证采完阶红间地第布容海须给政决任据市选头先。经种连组最二月风石重论力清县原活张技被四务共电则属声每时市头只。
          </span>
            <div class="text-wrapper_13 flex-row justify-between">
              <span class="text_29">软装</span>
              <span class="text_30">知识</span>
            </div>
          </div>
          <div class="box_5 flex-col"></div>
          <div class="box_6 flex-col"></div>
          <div class="text-wrapper_14 flex-col"><span class="text_31">查看详情</span></div>
        </div>
      </div>
      <div class="section_13 flex-row">
        <div class="text-wrapper_26 flex-col"><span class="text_55">1</span></div>
        <div class="text-wrapper_27 flex-col"><span class="text_56">2</span></div>
        <div class="text-wrapper_28 flex-col"><span class="text_57">3</span></div>
        <div class="text-wrapper_29 flex-col"><span class="text_58">4</span></div>
        <div class="text-wrapper_30 flex-col"><span class="text_59">5</span></div>
        <div class="text-wrapper_31 flex-col"><span class="text_60">6</span></div>
        <div class="text-wrapper_32 flex-col"><span class="text_61">7</span></div>
        <div class="text-wrapper_33 flex-col"><span class="text_62">…</span></div>
        <div class="text-wrapper_34 flex-col"><span class="text_63">25</span></div>
        <div class="image-wrapper_7 flex-col">
          <img
              class="thumbnail_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng3ccd9261501e695a74a113491c898c362b8003886c595e9439d6b354c76eab3f.png"
          />
        </div>
        <span class="text_64">共25页</span>
        <span class="text_65">跳至</span>
        <div class="text-wrapper_35 flex-col"><span class="text_66">5</span></div>
        <span class="text_67">页</span>
      </div>
    </div>



    <Footer ref="footer"></Footer>
  </div>
</template>
<script>
import Menu from "../commons/menu/index.vue";
import Footer from "../commons/footer/index.vue";

export default {
  components: {Footer, Menu},
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    scrollToBottom() {
      console.log(this.$refs.footer.$refs.footer)
      this.$refs.footer.$refs.footer.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />
