<template>
  <div class="page flex-col">
    <Menu></Menu>
    <div class="group_5 flex-row">
      <div class="banner">
        <div class="section_2 flex-col"></div>
        <div class="section_3 flex-col">
          <div class="block_8 flex-row"><div class="block_9 flex-col"></div></div>
          <div class="block_10 flex-row"><div class="box_6 flex-col"></div></div>
          <div class="text-wrapper_13 flex-row"><span class="text_55">室内全案设计师</span></div>
          <div class="text-wrapper_14 flex-row"><span class="text_56">孙中旗</span></div>
          <div class="block_11 flex-row">
            <span class="text_57">邮箱</span>
            <span class="text_58">zhongqi&#64;163.com</span>
            <div class="block_12 flex-col"></div>
          </div>
          <div class="text-wrapper_15 flex-row justify-between">
            <span class="text_59">电话</span>
            <span class="text_60">17610399717</span>
          </div>
        </div>
      </div>

    </div>
    <div class="group_1 flex-col">
      <div class="box_1 flex-col">
        <div class="banner">
          <div class="text-wrapper_1 flex-row">
            <span class="text_1">个人经历</span>
            <span class="text_2">工作经验</span>
            <span class="text_3">工作地址</span>
          </div>
          <div class="image-wrapper_1 flex-row">
            <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngcb47424bd4275e85d0cb0114164035079e596c83aae85b96639c06aadccd3230.png"
            />
            <img
                class="thumbnail_2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngcb47424bd4275e85d0cb0114164035079e596c83aae85b96639c06aadccd3230.png"
            />
            <img
                class="thumbnail_3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngcb47424bd4275e85d0cb0114164035079e596c83aae85b96639c06aadccd3230.png"
            />
          </div>
          <div class="text-wrapper_2 flex-row">
            <span class="text_4">1993年出生</span>
            <span class="text_5">2017-2018&nbsp;北京齐家网&nbsp;&nbsp;室内设计师</span>
            <span class="text_6">北京市</span>
          </div>
          <div class="text-wrapper_3 flex-row justify-between">
            <span class="text_7">2017年毕业于燕京理工学院</span>
            <span class="text_8">2018-2019&nbsp;北京智屏科技有限公司&nbsp;&nbsp;室内设计师</span>
          </div>
          <div class="text-wrapper_4 flex-row">
            <span class="text_9">2019-2023&nbsp;北京精致生活有限公司&nbsp;室内全案设计师</span>
          </div>
          <img @click="scrollToBottom"
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng739e6b78835a69aee30d7a471e2bf9bbe2c1bf3b42ec7c99252994f2298d4c8e.png"
          />
        </div>
      </div>
      <div class="banner">
        <div class="box_2 flex-row">
          <span class="text_10">Hello&nbsp;Dajia</span>
          <div class="block_1 flex-col"></div>
          <div class="block_2 flex-col">
            <div class="block_3 flex-row">
              <span class="text_11">01</span>
              <img
                  class="thumbnail_4"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngf9efc8529e36415f642d1f60b0dbf2450b1d48d9b503bc1f8c35a2efa8dfb5d1.png"
              />
              <span class="text_12">may</span>
            </div>
            <span class="text_13">每日分享</span>
            <span class="text_14">
            好的界面设计并不始于图片，而是始于对人的理解，比如人们喜欢什么，为什么人们会试用某种特定的软件，他们可能与之怎样交互
          </span>
          </div>
        </div>
        <p class="text_15">设计大师</p>
        <p class="text_16">d/e/s/i/g/n/e/r</p>
        <div class="box_3 flex-row">
          <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngca1a9a30d3879fa45f943d01e7f05574baa7a8c268d305bf8bdf5aee8af7b0a3.png"
          />
          <div class="image-text_1 flex-col justify-between">
            <div class="section_1 flex-col"></div>
            <div class="text-group_1 flex-col justify-between">
              <span class="text_17">原研哉</span>
              <span class="text_18">平面设计大师｜日本</span>
            </div>
          </div>
          <div class="block_4 flex-col"></div>
          <div class="block_5 flex-col justify-between">
            <div class="group_2 flex-col"></div>
            <div class="text-group_2 flex-col justify-between">
              <span class="text_19">安藤忠雄</span>
              <span class="text_20">建筑师｜日本</span>
            </div>
          </div>
          <div class="block_6 flex-col"></div>
          <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnge736e7232a9173734f1c1c5f1de0da819efdfdd5d1ce6c767eeddfb1bf93d1f9.png"
          />
        </div>
        <div class="box_4 flex-row justify-between">
          <div class="text-group_3 flex-col justify-between">
            <span class="text_21">贝律铭</span>
            <span class="text_22">建筑师｜美国</span>
          </div>
          <div class="text-group_4 flex-col justify-between">
            <span class="text_23">柯布西耶</span>
            <span class="text_24">建筑大师｜法国</span>
          </div>
        </div>
        <p class="text_15">留言板</p>
        <p class="text_16">m/e/s/s/a/g/e</p>
        <div class="list_1 flex-row">
          <div
              class="list-items_1 flex-col"
              :style="{ background: item.lanhuBg0 }"
              v-for="(item, index) in loopData0"
              :key="index"
          >
            <div class="group_3 flex-row justify-between">
              <div class="group_4 flex-col" :style="{ background: item.lanhuBg2 }"></div>
              <span class="text_27" v-html="item.lanhutext0"></span>
            </div>
            <span class="text_28" v-html="item.lanhutext1"></span>
            <div class="text-wrapper_5">
              <span class="text_29" v-html="item.lanhutext2"></span>
              <span class="text_30" v-html="item.lanhutext3"></span>
            </div>
          </div>
        </div>
        <div class="image-text_2 flex-row justify-between" >
          <span class="text-group_5">查看更多</span>
          <img
              class="thumbnail_5"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngc7f49558ce02e1bedd23f08e3e8daa80b7e3bebf8d19292b3dade32e9439a505.png"
          />
        </div>

      </div>
    </div>
    <Footer ref="footer"></Footer>
  </div>
</template>
<script>
import Menu from "../commons/menu/index.vue";
import Footer from "../commons/footer/index.vue";

export default {
  components: {Footer, Menu},
  data() {
    return {
      loopData0: [
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          lanhuBg2:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/fa5918e5996543d7abdc4e011b4b7edc_mergeImage.png)',
          lanhutext0: '游客1656',
          lanhutext1:
            '设计师你好，我家正在装修，是一个老房子，我想整体改一下格局，但是设计师说房子挺老的了，害怕改动过大的话后续万一邻居有什么问题还需要我承担责任，我想问这个怎么处理呀？',
          lanhutext2: '回复：',
          lanhutext3: '您好，这边建议您听取设计师的建议哦，房子比较老的话，的确不建议改动比较大。'
        },
        {
          lanhuBg0: 'rgb(245, 248, 250,1.000000)',
          lanhuBg2:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/87dd633d0afe4cf89d1f46aec7ebf2e6_mergeImage.png)',
          lanhutext0: '游客1656',
          lanhutext1:
            '请问一下设计师，我家整体都是刷的暖色的墙漆，踢脚线感觉很难选，选白色感觉很不搭，选暖色又找不到一样的颜色，做隐形踢脚又实在是太贵了，我该选什么呀？',
          lanhutext2: '回复：',
          lanhutext3:
            '您好，如果考虑到整体性的话，还是选择比较接近的颜色会更好，但是不必太焦虑，因为后续家具进场以后，踢脚线露出来的面积很小的，对整体的影响不会很大的'
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          lanhuBg2:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/98871859e3e349f69ef5fe2a620f0e43_mergeImage.png)',
          lanhutext0: '游客1656',
          lanhutext1:
            '请问一下设计师，我家整体都是刷的暖色的墙漆，踢脚线感觉很难选，选白色感觉很不搭，选暖色又找不到一样的颜色，做隐形踢脚又实在是太贵了，我该选什么呀？怎么办怎么办怎么办…',
          lanhutext2: '回复：',
          lanhutext3:
            '使用设计工具的好处在于，当这些项目材料同时呈现，能够帮助我们进行模式识别，并促进更多创新结合体的出现，这些是当资源隐藏分散在各种文件夹、笔记本和幻灯片里时难以实现的'
        }
      ],
      constants: {}
    };
  },
  methods: {
    scrollToBottom() {
      console.log(this.$refs.footer.$refs.footer)
      this.$refs.footer.$refs.footer.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />
