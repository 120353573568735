<template>
  <div class="page">
    <Menu background="rgba(255, 255, 255, 1)"></Menu>
    <div class="banner">
      <div class="block_2 flex-col">
        <div class="box_1 flex-col justify-end">
          <div class="text-group_1 flex-col justify-between">
            <span class="text_8">advanced</span>
            <span class="text_9">house</span>
            <span class="text_10">高级的家带来高级的体验</span>
          </div>
          <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngeb76e59e218516be75bcb088e949032db0c6ad2bdac0c37919ae4d548ee2d80c.png"
          />
          <div class="group_1 flex-col"></div>
        </div>
      </div>
    </div>

    <div class="block_3 justify-center">
      <div class="image-text_1">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng48955961b943af75c9651c9f9fe3d16817c954cdf5a5eccd8addda3f30c14235.png"
        />
        <div class="text-group_2 flex-col">
          <span class="text_11">一千个人</span>
          <span class="text_12">就有一千个对家的想象</span>
          <span class="text_13">
            A&nbsp;thousand&nbsp;people&nbsp;have&nbsp;a&nbsp;thousand&nbsp;visions&nbsp;of&nbsp;home
          </span>
        </div>
        <img
            class="image_2" @click="scrollToBottom"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngf883d2dd53c4eee703bfb858dea4bfcf66008ea4073f202d9be9f451369ebb45.png"
        />
      </div>
    </div>
    <div class="jxxm">
      <p class="text_14">精选项目</p>
      <p class="text_15">S/e/l/e/c/t/e/d</p>
    </div>

    <div class="block_4 justify-center">
      <div class="banner2 justify-start">
        <div class="box_4 flex-col"></div>
        <div class="box_2 flex-col">
          <div class="box_3 flex-col"></div>
        </div>
        <div class="box_5 flex-col">
          <div class="text-group_3 flex-col justify-between">
            <span class="text_16">北京&nbsp;朝阳&nbsp;逸翠园</span>
            <span class="text_17">装修时间：2021.09-2022.05</span>
          </div>
          <div class="group_2 flex-col"></div>
          <span class="paragraph_1">
          究么们决打交光长事商领许立美速存战构于交题出由音张资经队问光世改基不他装况书说边事参身农海机声采积般实命细响积张记。
          <br />
          三传些江可际目合术品入族人义立参解选金学叫见件联即在小部写导识七影时再达小小想及发江而大。光日革气心影统先府标须示出义程土百酸区商九必就次什们包金量直。
          <br />
          发七准走准历市都主话往计每百器六这据大矿老图直之影种反真事位其联导式叫二争成来图。
        </span>
          <div class="text-wrapper_3 flex-col"><span class="text_18">查看详情</span></div>
        </div>
      </div>
      </div>

    <div class="block_5 flex-row">
      <div class="banner2 justify-start">
      <div class="section_1 flex-col">
        <div class="text-group_4 flex-col justify-between">
          <span class="text_19">北京&nbsp;朝阳&nbsp;国风上观</span>
          <span class="text_20">装修时间：2021.12-2022.11</span>
        </div>
        <div class="section_2 flex-col"></div>
        <span class="paragraph_2">
          究么们决打交光长事商领许立美速存战构于交题出由音张资经队问光世改基不他装况书说边事参身农海机声采积般实命细响积张记。
          <br />
          三传些江可际目合术品入族人义立参解选金学叫见件联即在小部写导识七影时再达小小想及发江而大。光日革气心影统先府标须示出义程土百酸区商九必就次什们包金量直。
          <br />
          发七准走准历市都主话往计每百器六这据大矿老图直之影种反真事位其联导式叫二争成来图。
        </span>
        <div class="text-wrapper_4 flex-col"><span class="text_21">查看详情</span></div>
      </div>
      <div class="box_7 flex-col"></div>
      <div class="section_3 flex-col">
        <div class="box_6 flex-col"></div>
      </div>
      </div>
    </div>
    <div class="block_6 flex-row justify-between">
      <div class="box_8 flex-col justify-between">
        <div class="box_9 flex-row justify-between">
          <div class="box_10 flex-col"></div>
          <div class="box_11 flex-col"></div>
        </div>
        <div class="box_12 flex-row justify-between">
          <div class="group_3 flex-col"></div>
          <div class="group_4 flex-col"></div>
        </div>
      </div>
      <div class="box_13 flex-col">
        <div class="text-group_5 flex-col justify-between">
          <span class="text_22">北京&nbsp;朝阳&nbsp;国际万象</span>
          <span class="text_23">装修时间：2021.02-2022.03</span>
        </div>
        <div class="box_14 flex-col"></div>
        <div class="box_15 flex-col"></div>
      </div>
    </div>
    <div class="text-wrapper_5 flex-col"><span class="text_24">查看详情</span></div>
    <div class="jxxm">
      <p class="text_14">其他项目</p>
      <p class="text_15">o/r/t/h/e/r</p>
    </div>

    <div class="block_7 flex-row">
      <div class="box_16 flex-row">
        <div class="image-text_2 flex-col justify-between">
          <div class="group_5 flex-col"></div>
          <div class="text-group_6 flex-col justify-between">
            <span class="text_27">北京&nbsp;海淀&nbsp;万福园</span>
            <span class="text_28">装修时间：2020.06-2021.09</span>
          </div>
        </div>
      </div>
      <div class="box_17 flex-col justify-between">
        <div class="box_18 flex-col"></div>
        <div class="text-group_7 flex-col justify-between">
          <span class="text_29">北京&nbsp;朝阳&nbsp;观湖国际</span>
          <span class="text_30">装修时间：2020.06-2021.09</span>
        </div>
      </div>
      <div class="box_19 flex-col justify-between">
        <div class="group_6 flex-col"></div>
        <div class="text-group_8 flex-col justify-between">
          <span class="text_31">北京&nbsp;顺义&nbsp;首开琅樾</span>
          <span class="text_32">装修时间：2020.06-2021.09</span>
        </div>
      </div>
      <div class="image-text_3 flex-col justify-between">
        <div class="section_4 flex-col"></div>
        <div class="text-group_9 flex-col justify-between">
          <span class="text_33">北京&nbsp;通州&nbsp;金福家园</span>
          <span class="text_34">装修时间：2020.06-2021.09</span>
        </div>
      </div>
    </div>
    <img
      class="image_3"
      referrerpolicy="no-referrer"
      src="./assets/img/SketchPngd6ebe1d088e4073043c1a2563f82f22f05c71cb833d3fb376201b2e9a7041f49.png"
    />
  <Footer ref="footer"></Footer>
  </div>
</template>
<script>
import Footer from "../commons/footer/index.vue";
import Menu from "../commons/menu/index.vue";

export default {
  components: {Menu, Footer},
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    scrollToBottom() {
      console.log(this.$refs.footer.$refs.footer)
      this.$refs.footer.$refs.footer.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />
