var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page flex-col"},[_c('Menu'),_vm._m(0),_c('div',{staticClass:"group_1 flex-col"},[_c('div',{staticClass:"box_1 flex-col"},[_c('div',{staticClass:"banner"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('img',{staticClass:"image_4",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPng739e6b78835a69aee30d7a471e2bf9bbe2c1bf3b42ec7c99252994f2298d4c8e.png")},on:{"click":_vm.scrollToBottom}})])]),_c('div',{staticClass:"banner"},[_vm._m(6),_c('p',{staticClass:"text_15"},[_vm._v("设计大师")]),_c('p',{staticClass:"text_16"},[_vm._v("d/e/s/i/g/n/e/r")]),_vm._m(7),_vm._m(8),_c('p',{staticClass:"text_15"},[_vm._v("留言板")]),_c('p',{staticClass:"text_16"},[_vm._v("m/e/s/s/a/g/e")]),_c('div',{staticClass:"list_1 flex-row"},_vm._l((_vm.loopData0),function(item,index){return _c('div',{key:index,staticClass:"list-items_1 flex-col",style:({ background: item.lanhuBg0 })},[_c('div',{staticClass:"group_3 flex-row justify-between"},[_c('div',{staticClass:"group_4 flex-col",style:({ background: item.lanhuBg2 })}),_c('span',{staticClass:"text_27",domProps:{"innerHTML":_vm._s(item.lanhutext0)}})]),_c('span',{staticClass:"text_28",domProps:{"innerHTML":_vm._s(item.lanhutext1)}}),_c('div',{staticClass:"text-wrapper_5"},[_c('span',{staticClass:"text_29",domProps:{"innerHTML":_vm._s(item.lanhutext2)}}),_c('span',{staticClass:"text_30",domProps:{"innerHTML":_vm._s(item.lanhutext3)}})])])}),0),_vm._m(9)])]),_c('Footer',{ref:"footer"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group_5 flex-row"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"section_2 flex-col"}),_c('div',{staticClass:"section_3 flex-col"},[_c('div',{staticClass:"block_8 flex-row"},[_c('div',{staticClass:"block_9 flex-col"})]),_c('div',{staticClass:"block_10 flex-row"},[_c('div',{staticClass:"box_6 flex-col"})]),_c('div',{staticClass:"text-wrapper_13 flex-row"},[_c('span',{staticClass:"text_55"},[_vm._v("室内全案设计师")])]),_c('div',{staticClass:"text-wrapper_14 flex-row"},[_c('span',{staticClass:"text_56"},[_vm._v("孙中旗")])]),_c('div',{staticClass:"block_11 flex-row"},[_c('span',{staticClass:"text_57"},[_vm._v("邮箱")]),_c('span',{staticClass:"text_58"},[_vm._v("zhongqi@163.com")]),_c('div',{staticClass:"block_12 flex-col"})]),_c('div',{staticClass:"text-wrapper_15 flex-row justify-between"},[_c('span',{staticClass:"text_59"},[_vm._v("电话")]),_c('span',{staticClass:"text_60"},[_vm._v("17610399717")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-wrapper_1 flex-row"},[_c('span',{staticClass:"text_1"},[_vm._v("个人经历")]),_c('span',{staticClass:"text_2"},[_vm._v("工作经验")]),_c('span',{staticClass:"text_3"},[_vm._v("工作地址")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper_1 flex-row"},[_c('img',{staticClass:"thumbnail_1",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPngcb47424bd4275e85d0cb0114164035079e596c83aae85b96639c06aadccd3230.png")}}),_c('img',{staticClass:"thumbnail_2",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPngcb47424bd4275e85d0cb0114164035079e596c83aae85b96639c06aadccd3230.png")}}),_c('img',{staticClass:"thumbnail_3",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPngcb47424bd4275e85d0cb0114164035079e596c83aae85b96639c06aadccd3230.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-wrapper_2 flex-row"},[_c('span',{staticClass:"text_4"},[_vm._v("1993年出生")]),_c('span',{staticClass:"text_5"},[_vm._v("2017-2018 北京齐家网  室内设计师")]),_c('span',{staticClass:"text_6"},[_vm._v("北京市")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-wrapper_3 flex-row justify-between"},[_c('span',{staticClass:"text_7"},[_vm._v("2017年毕业于燕京理工学院")]),_c('span',{staticClass:"text_8"},[_vm._v("2018-2019 北京智屏科技有限公司  室内设计师")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-wrapper_4 flex-row"},[_c('span',{staticClass:"text_9"},[_vm._v("2019-2023 北京精致生活有限公司 室内全案设计师")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_2 flex-row"},[_c('span',{staticClass:"text_10"},[_vm._v("Hello Dajia")]),_c('div',{staticClass:"block_1 flex-col"}),_c('div',{staticClass:"block_2 flex-col"},[_c('div',{staticClass:"block_3 flex-row"},[_c('span',{staticClass:"text_11"},[_vm._v("01")]),_c('img',{staticClass:"thumbnail_4",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPngf9efc8529e36415f642d1f60b0dbf2450b1d48d9b503bc1f8c35a2efa8dfb5d1.png")}}),_c('span',{staticClass:"text_12"},[_vm._v("may")])]),_c('span',{staticClass:"text_13"},[_vm._v("每日分享")]),_c('span',{staticClass:"text_14"},[_vm._v(" 好的界面设计并不始于图片，而是始于对人的理解，比如人们喜欢什么，为什么人们会试用某种特定的软件，他们可能与之怎样交互 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_3 flex-row"},[_c('img',{staticClass:"image_1",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPngca1a9a30d3879fa45f943d01e7f05574baa7a8c268d305bf8bdf5aee8af7b0a3.png")}}),_c('div',{staticClass:"image-text_1 flex-col justify-between"},[_c('div',{staticClass:"section_1 flex-col"}),_c('div',{staticClass:"text-group_1 flex-col justify-between"},[_c('span',{staticClass:"text_17"},[_vm._v("原研哉")]),_c('span',{staticClass:"text_18"},[_vm._v("平面设计大师｜日本")])])]),_c('div',{staticClass:"block_4 flex-col"}),_c('div',{staticClass:"block_5 flex-col justify-between"},[_c('div',{staticClass:"group_2 flex-col"}),_c('div',{staticClass:"text-group_2 flex-col justify-between"},[_c('span',{staticClass:"text_19"},[_vm._v("安藤忠雄")]),_c('span',{staticClass:"text_20"},[_vm._v("建筑师｜日本")])])]),_c('div',{staticClass:"block_6 flex-col"}),_c('img',{staticClass:"image_2",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPnge736e7232a9173734f1c1c5f1de0da819efdfdd5d1ce6c767eeddfb1bf93d1f9.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_4 flex-row justify-between"},[_c('div',{staticClass:"text-group_3 flex-col justify-between"},[_c('span',{staticClass:"text_21"},[_vm._v("贝律铭")]),_c('span',{staticClass:"text_22"},[_vm._v("建筑师｜美国")])]),_c('div',{staticClass:"text-group_4 flex-col justify-between"},[_c('span',{staticClass:"text_23"},[_vm._v("柯布西耶")]),_c('span',{staticClass:"text_24"},[_vm._v("建筑大师｜法国")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-text_2 flex-row justify-between"},[_c('span',{staticClass:"text-group_5"},[_vm._v("查看更多")]),_c('img',{staticClass:"thumbnail_5",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/SketchPngc7f49558ce02e1bedd23f08e3e8daa80b7e3bebf8d19292b3dade32e9439a505.png")}})])
}]

export { render, staticRenderFns }